import React from 'react';

const PlaceSiteIcon = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97803 23.9641L22.0338 47.0154C22.6536 48.1098 24.2286 48.1149 24.8555 47.0245L35.1505 29.1187C41.6066 18.9771 41.8772 11.8766 35.1505 4.87927C33.8618 3.53908 30.2021 0.472093 24.6995 0.0468403C20.9624 -0.23666 18.0501 0.832915 16.6197 1.47724C15.4341 2.00558 10.7306 4.22206 8.32081 9.63437C5.1894 16.6575 8.55277 23.1652 8.97803 23.9641ZM23.4974 23.9963C27.6389 23.9963 30.9963 20.6389 30.9963 16.4974C30.9963 12.3559 27.6389 8.99861 23.4974 8.99861C19.3559 8.99861 15.9986 12.3559 15.9986 16.4974C15.9986 20.6389 19.3559 23.9963 23.4974 23.9963Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};
export default PlaceSiteIcon;

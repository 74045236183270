import React from 'react';
import { navigatorContainer } from './OSDNavigator.module.scss';

const OSDNavigator = () => {
  // This id is set in the options in OSDViewer.js
  return (
    <div id="navigatorContainer" className={`${navigatorContainer}`}></div>
  );
};
export default OSDNavigator;

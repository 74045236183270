import React from 'react';
import ReactMarkdown from 'react-markdown';
import {} from './HotspotContentText.module.scss';

const HotspotContentText = ({ body, credit }) => {
  const touchwall = process.env.GATSBY_CAMDEN_OFFLINE === 'true';
  // Next time let's make sure we don't have to hide characters in addition to the link
  // If the links weren't wrapped in () then we could just add 'a' to ReactMarkdown's disallowedElements prop and not need regex
  const regexLink = / \(\[([^[\]]*)\]\((.*?)\)\)/; // Matching a link enclosed in parenthesis, ex. ([link](https://www.battleshipnewjersey.org))
  const bodyNoLinks = body.replace(regexLink, '');

  return (
    <>
      <ReactMarkdown children={touchwall ? bodyNoLinks : body} />
      {credit && (
        <span className={`credit`}>
          <ReactMarkdown children={`Photo: ${credit}`} />
        </span>
      )}
    </>
  );
};
export default HotspotContentText;

import React from 'react';
import { categoryBgColors } from '../vars/categoryColors';
import { centerTitle, filterButton } from './FilterButton.module.scss';

const FilterButton = ({
  category,
  title,
  icon,
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <button
      type="button"
      className={`${filterButton} buttonTextFilter ${
        category === activeCategory ? categoryBgColors[category] : ''
      }`}
      onClick={() =>
        setActiveCategory(activeCategory !== category ? category : 'NONE')
      }
    >
      {icon && <span>{icon}</span>}
      <span className={`${!icon ? centerTitle : ''}`}>{title}</span>
    </button>
  );
};
export default FilterButton;

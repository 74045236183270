import { graphql, useStaticQuery } from 'gatsby';
import gsap from 'gsap';
import React, { memo, useEffect, useRef } from 'react';
import { categoryBgColors } from '../vars/categoryColors';
import HotspotContentMedia from './HotspotContentMedia';
import HotspotContentText from './HotspotContentText';
import HotspotContentThumbnail from './HotspotContentThumbnail';

import {
  cardBody,
  cardContainer,
  cardFooter,
  cardHeader,
  cardImageContainer,
  cardThumb,
  showBody,
} from './HotspotCard.module.scss';

const HotspotCard = ({
  hotspot,
  activeHotspot,
  setActiveHotspot,
  activeCategory,
  index,
}) => {
  const {
    title,
    hotspot_id,
    body,
    category,
    mural_insert,
    media,
    osd,
    thumbnail,
    childrenStrapiHotspotCreditTextnode,
  } = hotspot;

  // TODO the pathPrefix was breaking videos in HotspotContentMedia on _dev. Do we still need this?
  const { site } = useStaticQuery(
    graphql`
      query CardSiteQuery {
        site {
          pathPrefix
        }
      }
    `
  );

  const isActive = activeHotspot === hotspot_id;

  const cardRef = useRef();
  const bodyRef = useRef();

  const toggleActiveHotspot = () => {
    if (isActive) {
      setActiveHotspot('');
    } else {
      setActiveHotspot(hotspot_id);
      bodyRef.current.focus();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleActiveHotspot();
    }
  };

  const filterTl = useRef();
  useEffect(() => {
    filterTl.current = gsap.timeline();

    if (
      activeCategory === hotspot.category ||
      activeCategory === 'ALL_MAP' ||
      activeCategory === 'ALL_MURAL'
    ) {
      // show the card
      filterTl.current
        .addLabel('start')
        .to(
          cardRef.current,
          {
            autoAlpha: 0,
            // display: 'block',
            duration: 0.3,
          },
          `start`
        )
        .to(cardRef.current, {
          autoAlpha: 1,
          display: 'block',
          duration: 0.3,
        });
    } else {
      // hide the card
      filterTl.current.addLabel('start').to(
        cardRef.current,
        {
          autoAlpha: 0,
          display: 'none',
          duration: 0.3,
        },
        'start'
      );
    }
  }, [activeCategory]);

  return (
    <li
      id={`card-${hotspot_id}`}
      className={`${cardContainer} ${categoryBgColors[category]}`}
      ref={cardRef}
    >
      <div
        className={`${cardHeader}`}
        role="button"
        tabIndex={0}
        onClick={toggleActiveHotspot}
        onKeyDown={handleKeyDown}
      >
        <div className={`${cardThumb}`}>
          <HotspotContentThumbnail
            osdId={osd.osd_id}
            muralInsert={mural_insert}
            thumbnail={thumbnail}
            media={media}
          />
        </div>
        <h2>
          {osd.osd_id === 'map' && (
            <span className={`srOnly`}>{index + 1} </span>
          )}
          {title}
        </h2>
      </div>
      <div
        className={`${cardBody} ${isActive ? showBody : ''}`}
        ref={bodyRef}
        tabIndex={-1}
      >
        <div className={`${cardImageContainer}`}>
          <HotspotContentMedia
            media={media}
            site={site}
            muralInsert={mural_insert}
            index={index}
          />
        </div>
        <HotspotContentText
          body={body?.data.body}
          credit={childrenStrapiHotspotCreditTextnode[0]?.credit}
        />
      </div>
      <div className={`${cardFooter}`}>
        <div className={`h4`} aria-hidden="true">
          {osd.osd_id === 'map' && index + 1}
        </div>
        {/* using a button rather than details/summary because their 
          events are really difficult to listen for and control */}
        <button
          className={`toggleButton ${isActive ? 'toggleButtonActive' : ''}`}
          onClick={toggleActiveHotspot}
        >
          {isActive ? 'Hide' : 'Show'}
        </button>
      </div>
    </li>
  );
};
export default memo(HotspotCard);

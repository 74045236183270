import React from 'react';

const WarIcon = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 39H48V42C48 43.6569 46.6569 45 45 45H3C1.34315 45 0 43.6569 0 42V39Z"
        fill="#F0F0F0"
      />
      <rect y="27" width="48" height="6" fill="#F0F0F0" />
      <rect x="24" y="15" width="24" height="6" fill="#F0F0F0" />
      <path d="M24 3H45C46.6569 3 48 4.34315 48 6V9H24V3Z" fill="#F0F0F0" />
      <path d="M0 6C0 4.34315 1.34315 3 3 3H18V21H0V6Z" fill="#F0F0F0" />
    </svg>
  );
};
export default WarIcon;

import React from 'react';
import FilterButton from './FilterButton';
import { filtersContainer } from './Filters.module.scss';
import EventIcon from './icons/EventIcon';
import OrganizationIcon from './icons/OrganizationIcon';
import PersonIcon from './icons/PersonIcon';
import PlaceSiteIcon from './icons/PlaceSiteIcon';
import WarIcon from './icons/WarIcon';

const Filters = ({ pageName, activeCategory, setActiveCategory }) => {
  const mapFilters = [
    { category: 'SITE', title: 'Historic Sites', icon: <PlaceSiteIcon /> },
    {
      category: 'ORGANIZATION',
      title: 'Historic Organizations',
      icon: <OrganizationIcon />,
    },
    { category: 'WAR', title: 'American Revolution Sites', icon: <WarIcon /> },
    { category: 'ALL_MAP', title: 'Toggle All', icon: '' },
  ];

  const muralFilters = [
    { category: 'EVENT', title: 'Events', icon: <EventIcon /> },
    { category: 'PERSON', title: 'People', icon: <PersonIcon /> },
    { category: 'PLACE', title: 'Places', icon: <PlaceSiteIcon /> },
    { category: 'ALL_MURAL', title: 'Toggle All', icon: '' },
  ];

  return (
    <div className={`${filtersContainer}`}>
      {pageName === 'Map' &&
        mapFilters.map(({ category, title, icon }) => (
          <FilterButton
            category={category}
            title={title}
            icon={icon}
            key={title}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        ))}
      {pageName === 'Mural' &&
        muralFilters.map(({ category, title, icon }) => (
          <FilterButton
            category={category}
            title={title}
            icon={icon}
            key={title}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        ))}
    </div>
  );
};
export default Filters;

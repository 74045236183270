import React from 'react';

const ArrowIcon = () => {
  return (
    <svg viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.5L11 7.5L0 14.5L0 0.5Z" fill="#F0F0F0" />
    </svg>
  );
};
export default ArrowIcon;

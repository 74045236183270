import React, { useState } from 'react';
import { categoryBgColors } from '../vars/categoryColors';
import HotspotContentThumbnail from './HotspotContentThumbnail';
import {
  arrowIcon,
  drawerClosed,
  drawerGrid,
  drawerItem,
  drawerTitle,
  hotspotDrawerContainer,
  mapItem,
  thumb,
} from './HotspotDrawer.module.scss';
import ArrowIcon from './icons/ArrowIcon';

const HotspotDrawer = ({
  activeCategory,
  hotspots,
  activeHotspot,
  setActiveHotspot,
  clickedScreen,
}) => {
  // TODO does this state need to be higher so any other elements can control it?
  const [drawerOpen, setDrawerOpen] = useState(false);
  // console.log(hotspots);

  return (
    <div
      className={`${hotspotDrawerContainer}  ${
        drawerOpen ? '' : drawerClosed
      } ${
        {
          leftScreen: 'navLeft',
          centerScreen: 'navCenter',
          rightScreen: 'navRight',
        }[clickedScreen]
      }`}
      // style={{
      //   margin: {
      //     leftScreen: '0 calc(100vw - 1080px) 0 0',
      //     centerScreen: '0 calc(50vw - 540px)',
      //     rightScreen: '0 0 0 calc(100vw - 1080px)',
      //   }[clickedScreen],
      // }}
    >
      <div
        className={`${drawerTitle} ${categoryBgColors[activeCategory]}`}
        role="button"
        tabIndex="0"
        onClick={() => setDrawerOpen(!drawerOpen)}
        onKeyDown={(e) =>
          (e.key === 'Enter' || e.key === ' ') && setDrawerOpen(!drawerOpen)
        }
      >
        <h2 className={`h6`}>
          {
            {
              NONE: 'Select A Category Below',
              // Mural
              EVENT: 'Events',
              PERSON: 'People',
              PLACE: 'Places',
              ALL_MURAL: 'Events, People, and Places',
              // Map
              SITE: 'Historic Sites',
              ORGANIZATION: 'Historic Organizations',

              WAR: 'American Revolution Sites',
              ALL_MAP:
                'Historic Sites, Historic Organizations, and American Revolution Sites',
            }[activeCategory]
          }
        </h2>
        {/* FIXME arrow needs to rotate */}
        <div className={`${arrowIcon}`}>
          <ArrowIcon />
        </div>
      </div>
      <ul className={`${drawerGrid} ${categoryBgColors[activeCategory]}`}>
        {hotspots.map(
          (
            {
              hotspot_id,
              title,
              category,
              osd,
              mural_insert,
              thumbnail,
              media,
            },
            index
          ) =>
            (category === activeCategory ||
              activeCategory === 'ALL_MURAL' ||
              activeCategory === 'ALL_MAP') && (
              <li key={index}>
                <button
                  type="button"
                  className={`${drawerItem} ${
                    osd.osd_id === 'map' ? mapItem : ''
                  } listItemTouchwall`}
                  onClick={() => setActiveHotspot(hotspot_id)}
                  onKeyDown={(e) =>
                    (e.key === 'Enter' || e.key === ' ') &&
                    setActiveHotspot(hotspot_id)
                  }
                  tabIndex={drawerOpen ? '0' : '-1'}
                >
                  {osd.osd_id === 'map' && (
                    <span className={`listNumberTouchwall textRight`}>
                      {index + 1}
                    </span>
                  )}
                  <span className={`${thumb}`}>
                    <HotspotContentThumbnail
                      osdId={osd.osd_id}
                      muralInsert={mural_insert}
                      thumbnail={thumbnail}
                      media={media}
                    />
                  </span>

                  {title}
                </button>
              </li>
            )
        )}
      </ul>
    </div>
  );
};
export default HotspotDrawer;
